import { process } from "@progress/kendo-data-query";
import { Button, ToolbarSpacer } from "@progress/kendo-react-buttons";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import {
  Grid,
  GridColumn,
  GridColumnMenuFilter,
  GridColumnMenuSort,
  GridToolbar
} from "@progress/kendo-react-grid";
import "@progress/kendo-theme-default/dist/all.css";
import React from "react";
import "./App.scss";
import { CustomColumnMenu } from "./components/customColumnMenu";
import { bannerFormats } from "./data/bannerFormats"; //default view
import { bannerFormatsNor } from "./data/bannerFormatsNor"; //test data 
import { bannerFormatsFin } from "./data/bannerFormatsFin"; //test data
import bannerSweden from "./data/bannerSweden.json"
import bannerNor from "./data/bannerNor.json"

import sweIcon from "./assets/se.svg"
import norIcon from "./assets/no.svg"
import finIcon from "./assets/fi.svg"
import denIcon from "./assets/dk.svg"


import { columns } from "./data/columns";

//Also see https://www.telerik.com/kendo-react-ui/components/grid/filtering/ for more information. 
//I think we currently update/create states twice for some reason, might want to look into that.  
export default function App() {

  const [stateColumns, setStateColumns] = React.useState(columns);
  const [country, setCountry] = React.useState("DENMARK");  //Just the H1 title that changes depending on country
  const fileName = `GroupM ${country} Banner Specs ${new Date().getFullYear()}`; //File name changes depending on country and year


  const _grid = React.useRef();

  let _export = React.useRef(null);
  // let height = {height: "1000px"} //If we want scrollable grid at some point 
  const onColumnsSubmit = (columnsState) => {

    setStateColumns(columnsState);
  };

  const data1 = bannerFormats;  //danish specs default
  const data2 = bannerSweden;
  const data3 = bannerNor;
  const data4 = bannerFormatsFin;

  const [activeData, setActiveData] = React.useState(data1);  //sets danish specs as active default. This state keeps track of current country data. 

  const createDataState = (dataState) => {
    return {
      result: process(activeData, dataState), //processes current active data i.e. giving the json a result and a state
      dataState: dataState,
    };
  };

  let initialState = createDataState({});
  const [result, setResult] = React.useState(initialState.result);  //setting initial result
  const [dataState, setDataState] = React.useState(initialState.dataState);   //Setting initial dataState

  //Thank you chatGPT for this magic, whatever it is.. 
  React.useEffect(() => {
    let newState = createDataState({});
    setResult(newState.result);
    setDataState(newState.dataState);
  }, [activeData]);

  //Controlling the main data input i.e. county json
  const changeData = (data) => {
    setActiveData(data);
    let newState = createDataState({});
    setResult(newState.result);
    setDataState(newState.dataState);
  };


  //Need individual colors of the country buttons. default secondary. 
  const [themeColorSwe, setThemeColorSwe] = React.useState("secondary");
  const [themeColorNor, setThemeColorNor] = React.useState("secondary");
  const [themeColorFin, setThemeColorFin] = React.useState("secondary");
  const [themeColorDen, setThemeColorDen] = React.useState("primary");
  


  //controlling updates to data state i.e. when filtering
  const dataStateChange = (event) => {
    console.log("Updated Data State " + activeData.length);
    let updatedState = createDataState(event.dataState);
    setResult(updatedState.result);
    setDataState(updatedState.dataState);

  };

  //Handling click switch between country specs
  const handleDataSwitch = (buttonName) => {

    //Depending on the button clicked, set the other buttons to secondary and set activeData to either default/danish or new data source. 
    //I'm sure this could be done in fewer lines, but it works. 
    switch (buttonName) {
      case "denBt":
        setThemeColorDen(themeColorDen === "secondary" ? "primary" : "secondary");
        setThemeColorNor("secondary");
        setThemeColorFin("secondary");
        setThemeColorSwe("secondary");
        if (activeData !== data1) {
          changeData(data1)
          setCountry("DENMARK")
          //push event for tracking
          window.dataLayer.push({
            event: "denmarkClick",
          })
        }
        else {
          changeData(data1)
          setCountry("DENMARK")
          setThemeColorDen("primary");

        }

        return;
      case "sweBt":
        setThemeColorSwe(themeColorSwe === "secondary" ? "primary" : "secondary");
        setThemeColorNor("secondary");
        setThemeColorFin("secondary");
        setThemeColorDen("secondary");
        if (activeData !== data2) {
          changeData(data2)
          setCountry("SWEDEN")
          //push event for tracking
          window.dataLayer.push({
            event: "swedenClick",
          })
        }
        else {
          changeData(data1)
          setCountry("DENMARK")
          setThemeColorDen("primary");

        }

        return;
      case "norBt":
        setThemeColorNor(themeColorNor === "secondary" ? "primary" : "secondary");
        setThemeColorSwe("secondary")
        setThemeColorFin("secondary");
        setThemeColorDen("secondary");

        if (activeData !== data3) {
          changeData(data3)
          setCountry("NORWAY")
          window.dataLayer.push({
            event: "norwayClick",
          })
        }
        else {
          changeData(data1)
          setCountry("DENMARK")
          setThemeColorDen("primary");

        }
        return;
      case "finBt":
        setThemeColorFin(themeColorFin === "secondary" ? "primary" : "secondary");
        setThemeColorNor("secondary")
        setThemeColorSwe("secondary");
        setThemeColorDen("secondary");

        if (activeData !== data4) {
          changeData(data4)
          setCountry("FINLAND")
          window.dataLayer.push({
            event: "finlandClick",
          })
        }
        else {
          changeData(data1)
          setCountry("DENMARK")
          setThemeColorDen("primary");

        }
        return;
      default:
        changeData(data1);
        return;
    }
  }

  const exportExcel = () => {
    if (_export.current !== null) {
      _export.current.save(result, stateColumns);

      //push event for tracking
      window.dataLayer.push({
        event: "export_excel",
        amountOfRows: result.data.length,
        country: country,
        stateColumns: stateColumns
      });
    }
  };

  const columnProps = (field) => {
    return {
      field: field,
      ColumnMenuStyling: ColumnMenuStyling,
      headerClassName: isColumnActive(field, dataState) ? "active" : "",
    };
  };

  const isColumnActive = (field, dataState) => {
    return (
      GridColumnMenuFilter.active(field, dataState.filter) ||
      GridColumnMenuSort.active(field, dataState.sort)
    );
  };
  const ColumnMenuStyling = (props) => {
    return (
      <div>
        <GridColumnMenuSort {...props} />
        <GridColumnMenuFilter {...props} />
      </div>
    );
  };

  const GridElement = (
    <Grid
      data={result}
      {...dataState}
      reorderable={false} //Bugged so set to false for now
      resizable={false}
      sortable
      //style={height}
      scrollable="none"
      ref={_grid}
      onDataStateChange={dataStateChange}
      {...dataState}
    >
      <GridToolbar>

        <Button
          themeColor={"success"}
          icon="excel"
          onClick={exportExcel}
          rounded="large"
        >
          Export to Excel
        </Button >

        <div >  
          {result.data.length === activeData.length
          ? "Click the three dots to filter the specs you need. Some information only becomes available once you export." // hide the div when the condition is true
          : `Currently showing ${result.data.length}/${activeData.length} rows. Now just export and share the file.`}
        </div>
        
        <ToolbarSpacer>
        </ToolbarSpacer>
        <Button
          disabled={false}
          themeColor={themeColorDen}
          onClick={() => handleDataSwitch("denBt")}
          rounded="large"
        >
          <img id="countryIcon" src={denIcon} alt="DK Icon" />
          Danish Specs
        </Button>
        <Button
          themeColor={themeColorSwe}
          onClick={() => handleDataSwitch("sweBt")}
          rounded="large"
        >
          <img id="countryIcon" src={sweIcon} alt="Se Icon" />
          Swedish Specs
        </Button>
        <Button
          hidden={false}
          themeColor={themeColorNor}
          onClick={() => handleDataSwitch("norBt")}
          rounded="large"
        >
          <img id="countryIcon" src={norIcon} alt="Se Icon" />
          Norwegian Specs
        </Button>
        <Button
          hidden={true}
          themeColor={themeColorFin}
          onClick={() => handleDataSwitch("finBt")}
          rounded="large"
        >
          <img id="countryIcon" src={finIcon} alt="Se Icon" />
          Finish Specs
        </Button>
      </GridToolbar>

      {stateColumns.map(
        (column, idx) =>
          column.show && (
            <GridColumn
              key={idx}
              field={column.field}
              title={column.title}
              minWidth="200px"
              columnMenu={(props) => (
                <CustomColumnMenu
                  {...props}
                  columns={stateColumns}
                  onColumnsSubmit={onColumnsSubmit}
                  data={result}
                />
              )}
              {...columnProps(column.field)}
            />
          )
      )}
    </Grid>
  );
  return (
    <div className="App">
      <div className="card-container1">
        <div className="header-title">
          <h2>
            CREATIVE SPECIFICATIONS <br></br> GROUPM {country}
          </h2>
        </div>
      </div>
      <div className="card-container2">
      <h3 style={{color:"white", display: "flex", justifyContent: "center"}}> Filter - Export - Share
        </h3>
        <div className="grid-component" scrollable="virtual">
          <ExcelExport data={bannerFormats} ref={_export} fileName={fileName} />
          {GridElement}
        </div>
        <h4 style={{color:"white", display: "flex", justifyContent: "center"}}>Found an error? Please email us at
          <a style={{color:"lightblue"}} href="mailto:mikkel.aaxman@groupm.com">&nbsp;mikkel.aaxman@groupm.com</a>
        </h4>
      </div>
    </div>
  );
}
